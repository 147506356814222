import { getCookie } from 'services/cookies'

const strangePaths = Object.freeze([
  'api/asins',
  'api/segments',
  'api/share_of_voice',
  'api/product_tracks',
  'api/product_categories',
  'api/products/update_product'
])

const isStrangeEnabled = () => {
  return !!getCookie('___strange_enabled')
}

const isCobaltLocalEnv = () =>
  process.env.NODE_ENV === 'development' && window?.location?.port === '9090'

const isCobalt = () =>
  isCobaltLocalEnv() || window?.location?.origin?.includes('cobalt')

const isStrangeEndpoint = path => {
  if (!isCobalt() || !isStrangeEnabled()) {
    return false
  }

  return strangePaths.some(p => path.startsWith(p))
}

const isTolkienEndpoint = path => {
  return path.startsWith('tolkien')
}

const isArkenstoneEndpoint = path => {
  return path.startsWith('arkenstone')
}

export const getApiRoot = path => {
  if (isStrangeEndpoint(path)) {
    return `${process.env.REACT_APP_GATEWAY_API_ROOT}/strange`
  }
  if (isTolkienEndpoint(path) || isArkenstoneEndpoint(path)) {
    return process.env.REACT_APP_GATEWAY_API_ROOT
  }

  return process.env.REACT_APP_API_ROOT
}
