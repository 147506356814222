import moment from 'moment'

import { humanizePriceFormatterWithDecimals } from 'helpers/formatters'
import {
  ALERTS_LOCAL_LAST_VIEWED_ID,
  ALERT_FILTER_KEYS
} from '../constants/alerts'
import { getLocalData, setLocalData } from './storage'

export const groupedAlertsByScrapedDate = alerts =>
  alerts.reduce((all, alert) => {
    const date = new Date(alert.scraped_at)
    date.setHours(0, 0, 0, 0)

    all[date] = all[date] || []
    all[date].push(alert)
    return all
  }, {})

export const parseDiffs = alert => {
  const columns = ['old_value', 'new_value']
  const dimensionKeys = ['length', 'width', 'height']
  let dimensionsUpdated = false

  const parseDiffDimensions = (alert, diff, column) => {
    const values = dimensionKeys.reduce((all, key, index) => {
      const data = diff[key] ||
        alert.diffs.find(d => d.key === key) || { [column]: alert.product[key] }
      all[index] = data[column]
      return all
    }, [])

    return values.join(' x ')
  }

  return alert.diffs
    .reduce((all, diff) => {
      const newDiff = { ...diff }

      if (dimensionKeys.includes(diff.key)) {
        if (dimensionsUpdated) {
          return all
        }

        dimensionsUpdated = true
        newDiff.key = 'dimensions'

        columns.forEach(column => {
          newDiff[column] = parseDiffDimensions(alert, diff, column)
        })
      }

      return [...all, newDiff]
    }, [])
    .sort((a, b) => b.id - a.id)
}

export const setLocalAlertsLastViewedId = id =>
  setLocalData(ALERTS_LOCAL_LAST_VIEWED_ID, id)

export const getLocalAlertsLastViewedId = () => {
  const id = getLocalData(ALERTS_LOCAL_LAST_VIEWED_ID)
  return id === null ? id : parseInt(id, 10)
}

export const isFilteringAlerts = ({ asin, source, keys }) => {
  return asin !== '' || source !== '' || keys !== ALERT_FILTER_KEYS
}

export const alertDiffMessage = (
  t,
  alertDiff,
  showProductName,
  currency = 'USD'
) => {
  const { key, old_value, new_value, alert } = alertDiff
  const increased = () => parseInt(new_value, 10) > parseInt(old_value, 10)

  const defaultValues = { old_value, new_value }
  const intValues = {
    old_value: parseInt(old_value, 10),
    new_value: parseInt(new_value, 10),
    diff_value: Math.abs(parseInt(new_value, 10) - parseInt(old_value, 10))
  }
  const priceValues = {
    old_value: humanizePriceFormatterWithDecimals(old_value, currency),
    new_value: humanizePriceFormatterWithDecimals(new_value, currency)
  }

  const messageMapping = {
    rank: increased()
      ? t(
          'alerts:AlertDiff.messages.rankIncreased',
          'BSR increased from {{old_value}} to {{new_value}}',
          intValues
        )
      : t(
          'alerts:AlertDiff.messages.rankDecreased',
          'BSR decreased from {{old_value}} to {{new_value}}',
          intValues
        ),
    price: increased()
      ? t(
          'alerts:AlertDiff.messages.priceIncreased',
          'Price increased from {{old_value}} to {{new_value}}',
          priceValues
        )
      : t(
          'alerts:AlertDiff.messages.priceDecreased',
          'Price decreased from {{old_value}} to {{new_value}}',
          priceValues
        ),
    rating: t(
      'alerts:AlertDiff.messages.rating',
      'Star rating changed from {{old_value}} to {{new_value}}',
      defaultValues
    ),
    sellerName: t(
      'alerts:AlertDiff.messages.sellerName',
      'Buy box owner changed from {{old_value}} to {{new_value}}',
      defaultValues
    ),
    nSellers: increased()
      ? t(
          'alerts:AlertDiff.messages.nSellersIncreased',
          'Number of sellers on listing increased from {{old_value}} to {{new_value}}',
          intValues
        )
      : t(
          'alerts:AlertDiff.messages.nSellersDecreased',
          'Number of sellers on listing decreased from {{old_value}} to {{new_value}}',
          intValues
        ),
    isUnavailable:
      new_value === 'true' || new_value === 't'
        ? t(
            'alerts:AlertDiff.messages.isUnavailable',
            'Product is out of stock'
          )
        : t(
            'alerts:AlertDiff.messages.isAvailable',
            'Product is back in stock'
          ),
    nReviews: increased()
      ? t(
          'alerts:AlertDiff.messages.nReviewsAdded',
          '{{ diff_value }} reviews added',
          intValues
        )
      : t(
          'alerts:AlertDiff.messages.nReviewsRemoved',
          '{{ diff_value }} reviews removed',
          intValues
        ),
    name: t(
      'alerts:AlertDiff.messages.name',
      'Product name changed from {{old_value}}',
      { old_value }
    ),
    calculatedCategory: t(
      'alerts:AlertDiff.messages.calculatedCategory',
      'Category changed from {{old_value}} to {{new_value}}',
      defaultValues
    ),
    imageUrl: t('alerts:AlertDiff.messages.imageUrl', 'Main image changed'),
    dimensions: t(
      'alerts:AlertDiff.messages.dimensions',
      'Dimensions changed from {{old_value}} to {{new_value}}',
      defaultValues
    ),
    weight: t(
      'alerts:AlertDiff.messages.weight',
      'Weight changed from {{old_value}} to {{new_value}}',
      defaultValues
    )
  }

  if (showProductName) {
    return t(
      'alerts:AlertDiff.messages.withAlertName',
      '{{ messageMapping }} for {{ alertName }}',
      { messageMapping: messageMapping[key], alertName: alert?.product?.name }
    )
  }

  return messageMapping[key]
}

export const getExportColumns = t => {
  return [
    {
      key: 'date',
      header: t('alerts:ExportAlerts.Columns.date', 'Date'),
      width: 15
    },
    {
      key: 'martketplace',
      header: t('alerts:ExportAlerts.Columns.martketplace', 'Martketplace'),
      width: 15
    },
    {
      key: 'asin',
      header: t('alerts:ExportAlerts.Columns.asin', 'ASIN'),
      width: 15
    },
    {
      key: 'productTitle',
      header: t('alerts:ExportAlerts.Columns.productTitle', 'Product Title'),
      width: 40,
      style: {
        alignment: {
          wrapText: true
        }
      }
    },
    {
      key: 'alertType',
      header: t('alerts:ExportAlerts.Columns.alertType', 'Alert Type'),
      width: 15
    },
    {
      key: 'alert',
      header: t('alerts:ExportAlerts.Columns.alert', 'Alert'),
      width: 20
    }
  ]
}

const getAlertType = (key, t) => {
  return {
    rank: t('alerts:ExportAlerts.alertType.rank', 'Rank'),
    price: t('alerts:ExportAlerts.alertType.price', 'Price'),
    rating: t('alerts:ExportAlerts.alertType.rating', 'Rating'),
    sellerName: t(
      'alerts:ExportAlerts.alertType.sellerName',
      'Buy Box Changes'
    ),
    nSellers: t(
      'alerts:ExportAlerts.alertType.nSellers',
      '#of sellers on listings'
    ),
    isUnavailable: t(
      'alerts:ExportAlerts.alertType.isUnavailable',
      'Avaliablity (Suppressed/Out of Stock)'
    ),
    nReviews: t('alerts:ExportAlerts.alertType.nReviews', 'Reviews'),
    calculatedCategory: t(
      'alerts:ExportAlerts.alertType.calculatedCategory',
      'Category'
    ),
    imageUrl: t('alerts:ExportAlerts.alertType.imageUrl', 'Main Image'),
    name: t('alerts:ExportAlerts.alertType.name', 'Product Name'),
    dimensions: t('alerts:ExportAlerts.alertType.dimensions', 'Dimensions'),
    weight: t('alerts:ExportAlerts.alertType.weight', 'Weight')
  }[key]
}

export const formatExcelRow = (alerts, t) => {
  return alerts.reduce((all, alert) => {
    const expandedAlerts = alert.diffs.map(diff => {
      return {
        date: alert.scraped_at
          ? moment(alert.scraped_at).format('MM/DD/YYYY')
          : null,
        martketplace: alert.product?.country || null,
        asin: alert.product?.asin || null,
        productTitle: alert.product?.name || null,
        alertType: getAlertType(diff.key, t) || null,
        alert:
          alertDiffMessage(t, diff, false, alert.product.currency_code) || null
      }
    })
    return [...all, ...expandedAlerts]
  }, [])
}
