import * as Yup from 'yup'
import pickBy from 'lodash/pickBy'

import { INVALID_EMAIL_MESSAGE } from 'constants/account'

export const createUserValidationSchema = () => {
  return Yup.object().shape({
    emails: Yup.string()
      .email(INVALID_EMAIL_MESSAGE)
      .required('Required'),
    teamIds: Yup.array()
      .min(1)
      .required('Please select at least one team.')
  })
}

export const UserEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(INVALID_EMAIL_MESSAGE)
    .required('Please enter a valid email format.')
})

const pendoMwsSyncLocations = [
  {
    route: 'amazon-settings',
    tag: 'amazon_settings'
  },
  {
    route: 'inventory',
    tag: 'inventory'
  },
  {
    route: 'listings',
    tag: 'listing_builder'
  },
  {
    route: 'other-transactions',
    tag: 'other_transactions'
  },
  {
    route: 'profit-loss',
    tag: 'profit_loss'
  },
  {
    route: 'profit-overview',
    tag: 'profit_overview'
  },
  {
    route: 'setup',
    tag: 'setup_wizard'
  },
  {
    route: 'review-automation',
    tag: 'review_automation'
  },
  {
    route: 'product-costs-and-settings',
    tag: 'product_costs_and_settings'
  }
]

export const extractMswSyncLocationForPendo = location => {
  let pendoTag = 'other'
  pendoMwsSyncLocations.forEach(pendoParam => {
    if (location.includes(pendoParam.route)) {
      pendoTag = pendoParam.tag
    }
  })
  return pendoTag
}

export const validateEmail = email =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,4}$/i.test(email)

export const filterGlobalData = globalData => {
  const excludeKeys = ['pendo']
  return pickBy(globalData, (_val, key) => !excludeKeys.includes(key))
}

export const isFromStripeCheckout = checkout => {
  if (checkout === 'stripe_checkout') {
    return true
  }
  return false
}
