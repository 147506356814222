import {
  formatDateAxisMonthFull,
  formatDateAxisMonthDayFull,
  formatDateAxisRange
} from 'helpers/charts'
import { isValidNumber } from 'helpers/values'
import { getCurrencyCode, parseCurrency } from 'helpers/currency'

export const formatDateTick = (date, endDate, interval, format) => {
  switch (interval) {
    case 'daily':
      return formatDateAxisMonthDayFull(date, format)
    case 'weekly':
      return formatDateAxisRange(date, endDate, format)
    default:
      return formatDateAxisMonthFull(date, format)
  }
}

export const addFormattedDate = (data, interval, format) =>
  data.map(item => {
    const formattedDate = formatDateTick(
      item.date,
      item.endDate,
      interval,
      format
    )
    return {
      ...item,
      formattedDate,
      rawGroupData: [{ ...item, formattedDate }]
    }
  })

export const currencyCell = ({ column, row }) => {
  const { id } = column
  const { original } = row

  const value = original[id]
  const currencyCode = getCurrencyCode(original.countryCode)

  if (isValidNumber(value) && Number(value) !== 0) {
    return parseCurrency(value, currencyCode)
  }

  return '-'
}

export const percentageCell = ({ column, row }) => {
  const { id } = column
  const { original } = row

  const value = original[id]

  if (value && Number(value) !== 0) {
    return `${parseFloat(value).toFixed(2)}%`
  }

  return '-'
}
