import { MARKETPLACES } from 'constants/countries'
import { ASIN_REGEX } from '../constants/asin'

export const parseAsin = value => {
  const regex = new RegExp(ASIN_REGEX, 'gi')
  if (regex.test(value)) return value.match(regex)[0]

  return null
}

export const isAsin = value => {
  const exactAsinRegex = new RegExp(`^${ASIN_REGEX}$`, 'gi')
  return exactAsinRegex.test(value)
}

export const isProductId = value => {
  if (!value) {
    return false
  }

  const splitProductId = value.split('/')
  if (!splitProductId || splitProductId.length !== 2) {
    return false
  }

  return isAsin(splitProductId[1])
}

export const parseAsinFromLink = link => {
  const asinRegex = /\/[A-Z0-9]{10}[/|?]*/
  const matches = link.match(asinRegex)
  const asin = matches && matches[0]

  if (asin) {
    return asin.replace(/\//g, '').replace(/\?/g, '')
  }

  return null
}

export const everyIsAsin = values => {
  return Array.isArray(values) && values.length > 0 && values.every(isAsin)
}

export const amazonUrl = (asin, country) => {
  const countryCode = country || 'us'
  const mapping = {
    us: 'com',
    jp: 'co.jp',
    uk: 'co.uk',
    au: 'com.au',
    br: 'com.br',
    mx: 'com.mx',
    tr: 'com.tr'
  }
  const topLevelDomain = mapping[countryCode.toLowerCase()] || country

  return `https://www.amazon.${topLevelDomain}/dp/${asin}`
}

export const parseMarketplaceFromUrl = url => {
  const res = url.match(/amazon.([\w|.]*)/)

  if (!res) {
    return null
  }
  const code = res[1]
  let marketplace = 'us'

  if (code === 'com') {
    return marketplace
  }

  Object.values(MARKETPLACES).map(item => {
    if (item.topDomain === code) {
      marketplace = item.code
    }
    return false
  })

  return marketplace
}
