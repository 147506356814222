import * as Yup from 'yup'

import { INVALID_THUMBNAIL_ERROR } from 'constants/admin/academy'
import { REQUIRED_ERROR_MSG } from 'constants/admin'

export const getAcademyGroupFormValues = group => {
  const values = {
    title: '',
    academy_content_type: false,
    description: '',
    video_language: ''
  }

  for (const key in group) {
    switch (key) {
      case 'academy_content_type':
        values[key] = group[key] === 'courses'
        break
      default:
        values[key] = group[key]
    }
  }

  return values
}

export const getAcademyCourseFormValues = course => {
  const values = {
    title: '',
    description: '',
    feature_image: '',
    academy_resources: [],
    coming_soon: false
  }

  for (const key in course) {
    values[key] = course[key]
  }

  return values
}

export const getAcademyLessonFormValues = lesson => {
  const values = {
    title: '',
    description: '',
    thumbnail_image: '',
    wistia_id: '',
    duration: '',
    academy_resources: []
  }

  for (const key in lesson) {
    values[key] = lesson[key]
  }

  return values
}

export const getAcademyGroupSaveObject = (values, mode) => {
  return {
    ...values,
    academy_content_type: values.academy_content_type ? 'courses' : 'lessons',
    id: mode === 'create' ? `group-${Date.now()}` : values.id
  }
}

export const getAcademyCourseSaveObject = (values, mode, count) => {
  return {
    ...values,
    id: mode === 'create' ? `course-${count}` : values.id
  }
}

export const getAcademyLessonSaveObject = (values, mode, count) => {
  return {
    ...values,
    id: mode === 'create' ? `lesson-${count}` : values.id
  }
}

export const createAcademyGroupValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MSG),
    description: Yup.string().when('academy_content_type', {
      is: false,
      then: Yup.string().required(REQUIRED_ERROR_MSG),
      otherwise: Yup.string()
    })
  })
}

const validateThumbnailUrl = url => /^https:\/\//.test(url)

export const createAcademyCourseValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MSG),
    description: Yup.string().when('coming_soon', {
      is: false,
      then: Yup.string().required(REQUIRED_ERROR_MSG),
      otherwise: Yup.string()
    }),
    feature_image: Yup.string().when('coming_soon', {
      is: false,
      then: Yup.string()
        .required(REQUIRED_ERROR_MSG)
        .test('is-valid', INVALID_THUMBNAIL_ERROR, function test(value) {
          return validateThumbnailUrl(value)
        }),
      otherwise: Yup.string().test(
        'is-valid',
        INVALID_THUMBNAIL_ERROR,
        function test(value) {
          if (!value) {
            return true
          }
          return validateThumbnailUrl(value)
        }
      )
    }),
    coming_soon: Yup.bool().required(REQUIRED_ERROR_MSG)
  })
}

export const createAcademyLessonValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MSG),
    description: Yup.string().required(REQUIRED_ERROR_MSG),
    thumbnail_image: Yup.string().required(REQUIRED_ERROR_MSG),
    wistia_id: Yup.string().required(REQUIRED_ERROR_MSG),
    duration: Yup.number().required(REQUIRED_ERROR_MSG)
  })
}

export const updateArray = (arr, obj, key = 'id') =>
  arr.map(arrObj => (arrObj[key] === obj[key] ? obj : arrObj))

export const updateOrAddToArray = (arr, obj, key = 'id') => {
  const index = arr.findIndex(element => element[key] === obj[key])

  if (index !== -1) {
    return updateArray(arr, obj, key)
  }

  return [...arr, obj]
}

export const mergeArraysOfObjects = (arr1, arr2) => {
  return arr2.reduce((acc, obj) => updateArray(acc, obj), arr1)
}

export const getNewPosition = arr => {
  let position = 0

  if (arr.length > 0) {
    position = arr[arr.length - 1].position + 1
  }

  return position
}

export const formatAcademyItem = academyItem => {
  let formattedItem = academyItem
  if (
    formattedItem.academy_resources?.length &&
    typeof formattedItem.academy_resources[0] === 'object'
  ) {
    formattedItem = {
      ...academyItem,
      academy_resources: academyItem.academy_resources.map(
        resource => resource.id
      )
    }
  }

  return formattedItem
}
